import React from "react";
import "./Achievements.css";
import { Container, Row, Col } from "react-bootstrap";
import Cer1 from "../../Assets/Certificate1.png";
import Cer2 from "../../Assets/Certificate2.png";
import Cer3 from "../../Assets/Certificate3.png";
import Cer4 from "../../Assets/Certificate4.png";
import Cer5 from "../../Assets/Certificate5.png";
import Cer6 from "../../Assets/Certificate6.png";
function Achievements() {
  return (
    <>
      <Container fluid id="achievement3">
        <Container>
          <Row>
            <Col id="achievement2">Achievements</Col>
          </Row>
          <Row>
            <Col id="achievement1">
              We take pride in our journey towords excellence
            </Col>
          </Row>
          <Row id="achievement4">
            <Col id="achievement5" md={2} lg={2} xl={2} sm={6} xs={6}>
              {" "}
              <img src={Cer1} alt="" />
            </Col>
            <Col id="achievement5" md={2} lg={2} xl={2} sm={6} xs={6}>
              {" "}
              <img src={Cer2} alt="" />
            </Col>
            <Col id="achievement5" md={2} lg={2} xl={2} sm={6} xs={6}>
              {" "}
              <img src={Cer3} alt="" />
            </Col>
            <Col id="achievement5" md={2} lg={2} xl={2} sm={6} xs={6}>
              {" "}
              <img src={Cer4} alt="" />
            </Col>
            <Col id="achievement5" md={2} lg={2} xl={2} sm={6} xs={6}>
              {" "}
              <img src={Cer5} alt="" />
            </Col>
            <Col id="achievement5" md={2} lg={2} xl={2} sm={6} xs={6}>
              {" "}
              <img src={Cer6} alt="" />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Achievements;
