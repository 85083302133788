import React from "react";
import "./Careercard.css";
import { Col, Container, Row } from "react-bootstrap";

function Careercard(props) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col id="careercard1">
            <Row>
              <Col>
                {" "}
                <img src={props.icon} alt="" />{" "}
              </Col>
            </Row>
            <Row>
              <Col id="careercard2">{props.title}</Col>
            </Row>
            <Row>
              <Col>{props.description}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Careercard;
