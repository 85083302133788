import React, { useState } from "react";
import "./GetQuoteModal.css";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Utility from "../../Utils/Utility";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

function GetQuoteModal(props) {
  const [reason, setReason] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [orga, setOrga] = useState("");
  const [desc, setDesc] = useState("");

  const data = {
    reason: reason,
    name: name,
    email: email,
    phone: phone,
    organization: orga,
    description: desc,
  };

  const regEmail =
    /^[a-zA-Z0-9+.-]+@[a-zA-Z]+?(\.[a-zA-Z]{2,3})|(\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3})$/;
  const regPhone = /^\d{10}$/;

  const validation = () => {
    if (reason === "" || reason === undefined) {
      Utility.eToast("Reason required!");
      return false;
    } else if (name === "" || name === undefined) {
      Utility.eToast("Name required");
      return false;
    } else if (email === "" || email === undefined) {
      Utility.eToast("Email required!");
      return false;
    } else if (!regEmail.test(email)) {
      Utility.eToast("Valid email required!");
      return false;
    } else if (phone === "" || phone === undefined) {
      Utility.eToast("Phone required!");
      return false;
    } else if (!regPhone.test(phone)) {
      Utility.eToast("only 10 digit mobile number required!");
      return false;
    } else {
      return true;
    }
  };

  const postQuote = async () => {
    if (validation()) {
      // console.log("===========>", data);
      if (validation()) {
        const valRef = collection(db, "quote");
        await addDoc(valRef, data)
          .then((res) => {
            // console.log("454545454545454", res);
            Utility.sToast("Query sent");

            setReason("");
            setName("");
            setEmail("");
            setPhone("");
            setOrga("");
            setDesc("");

            props.onHide();
          })
          .catch((err) => {
            console.log(err);
            Utility.eToast("Failed, please try again later!");
          });
      }
    }
  };

  return (
    <>
      <Modal
        show={props.isOpen}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container
            style={{ padding: "1.4rem 1rem", fontFamily: "Work sans" }}
          >
            <Form>
              <Form.Label>
                Choose a reason for contact<span className="red">*</span>
              </Form.Label>
              <Form.Select
                className="mb-3 quotemodal1"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Select an option</option>
                <option value="Mobile app Development">
                  Mobile app Development
                </option>
                <option value="Website Development">Webapp Development</option>
                <option value="Website Development">Website Development</option>
                <option value="HR consultancy">HR consultancy</option>
              </Form.Select>

              {/* Name Field */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Name<span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  className="quotemodal1"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              {/* Email Field */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Email address<span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  className="quotemodal1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {/* phone number */}
              <Form.Group className="mb-3">
                <Form.Label>
                  Phone<span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Company/ Organization (optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  className="quotemodal1"
                  value={orga}
                  onChange={(e) => setOrga(e.target.value)}
                />
              </Form.Group>

              {/* Description Field */}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="quotemodal1"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Form.Group>

              <Row>
                <Col>
                  <Button id="quotemodal2" onClick={() => postQuote()}>
                    Get a quote
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GetQuoteModal;
