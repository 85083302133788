import React, { useEffect, useState } from "react";
import "./JobDetails.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, fileDb } from "../../firebase";
import { v4 } from "uuid";
import { collection, addDoc, doc } from "firebase/firestore";
import Utility from "../../Utils/Utility";

function JobDetails() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addr, setAddr] = useState("");
  const [loc, setLoc] = useState("");
  const [edu, setEdu] = useState("");
  const [orga, setOrga] = useState("");
  const [pjt1, setPjt1] = useState("");
  const [pjt2, setPjt2] = useState("");
  const [pjtNo, setPjtNo] = useState("");
  const [we, setWe] = useState("");
  const [np, setNp] = useState("");
  const [eCtc, setEctc] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [pan, setPan] = useState("");
  const [cv, setCv] = useState("");

  const location = useLocation();
  // console.log("==============>", location?.state?.id);
  const doccId = location?.state?.id?.id;

  const originalText = location?.state?.id?.job_desc;
  const [formattedText, setFormattedText] = useState(originalText);

  useEffect(() => {
    const sentences = originalText?.split(". ");
    const formattedText = sentences
      ?.map((sentence) => `&bull; ${sentence}`)
      ?.join("<br />");
    setFormattedText(formattedText);
  }, [originalText]);

  const handleAadhar = (e) => {
    console.log(e.target.files[0]);
    const files = ref(fileDb, `allAadhar/${v4()}`);
    uploadBytes(files, e.target.files[0])
      .then((res) => {
        console.log("1=============>", res);
        getDownloadURL(res?.ref)
          .then((val) => {
            console.log(val);
            setAadhar(val);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const handlePan = (e) => {
    console.log(e.target.files[0]);
    const files = ref(fileDb, `allPan/${v4()}`);
    uploadBytes(files, e.target.files[0])
      .then((res) => {
        console.log("2=============>", res);
        getDownloadURL(res?.ref)
          .then((val) => {
            console.log(val);
            setPan(val);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const handleCv = (e) => {
    // console.log(e.target.files[0]);
    const files = ref(fileDb, `allCv/${v4()}`);
    uploadBytes(files, e.target.files[0])
      .then((res) => {
        console.log("3=============>", res);
        getDownloadURL(res?.ref)
          .then((val) => {
            console.log(val);
            setCv(val);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // validation
  const regEmail =
    /^[a-zA-Z0-9+.-]+@[a-zA-Z]+?(\.[a-zA-Z]{2,3})|(\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3})$/;
  const regPhone = /^\d{10}$/;
  const validation = () => {
    if (name === "" || name === undefined) {
      Utility.eToast("Name required!");
      return false;
    } else if (email === "" || email === undefined) {
      Utility.eToast("Email required!");
      return false;
    } else if (!regEmail.test(email)) {
      Utility.eToast("Valid email required!");
      return false;
    } else if (!regPhone.test(phone)) {
      Utility.eToast("Only 10 digit mobile number allowed!");
      return false;
    } else if (we === "" || we === undefined) {
      Utility.eToast("Work experience required!");
      return false;
    } else if (np === "" || np === undefined) {
      Utility.eToast("Notice period required!");
      return false;
    } else if (cv === "" || cv === undefined) {
      Utility.eToast("CV required!");
      return false;
    } else {
      return true;
    }
  };

  const data = {
    name: name,
    email: email,
    phone: phone,
    // address: addr,
    // location: loc,
    // education: edu,
    // organization: orga,
    // project1: pjt1,
    // project2: pjt2,
    // totalproject: pjtNo,
    workexperience: we,
    noticeperiod: np,
    // expectedctc: eCtc,
    // aadhar: aadhar,
    // pan: pan,
    cv: cv,
  };

  const handleSubmit = async () => {
    if (validation()) {
      // console.log("++++++++++++", data);
      const parentRef = doc(db, "job_post", doccId);
      const subcollectionRef = collection(
        parentRef,
        `applications under ${doccId}`
      );

      try {
        const docRef = await addDoc(subcollectionRef, data);
        Utility.sToast("Profile sent");
        // console.log("Document written with ID: ", docRef.id);

        setName("");
        setEmail("");
        setPhone("");
        setWe("");
        setNp("");
        setCv("");
      } catch (err) {
        Utility.eToast("Failed, please try again later!");
        // console.error("Error writing document: ", err);
      }
    }
  };

  return (
    <>
      <Container fluid id="jobdetails1">
        <Row>
          <Col id="jobdetails2">{location?.state?.id?.job_name}</Col>
        </Row>
        {/* <Row>
          <Col id="jobdetails3">Job Type: Full Time, No of Vacancies:02</Col>
        </Row> */}
      </Container>
      <Container className="jobdetails10">
        <Row>
          <Col md={6} xl={6} lg={6} xs={12} sm={12}>
            <Row>
              <Col id="jobdetails4">{location?.state?.id?.job_name}</Col>
            </Row>
            <Row>
              <Col id="jobdetails5"> Who Are We Looking For </Col>
            </Row>
            {/* <Row>
              <Col>
                <ul id="jobdetails6">
                  <li>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li> Nullam dictum ligula a gravida porta.</li>
                  <li>
                    {" "}
                    Nam pellentesque orci ut odio blandit, sit amet elementum
                    augue venenatis.
                  </li>
                  <li>
                    {" "}
                    Vivamus semper magna suscipit leo malesuada, eu dictum velit
                    varius.
                  </li>
                  <li> Nulla non enim eu quam rutrum dictum in non urna.</li>
                  <li>
                    {" "}
                    Integer et felis a purus convallis condimentum nec vel eros.
                  </li>
                  <li>Vestibulum porta libero nec aliquet blandit.</li>
                </ul>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <div dangerouslySetInnerHTML={{ __html: formattedText }} />
              </Col>
            </Row>

            {/* <Row>
              <Col id="jobdetails5">What You Will Be Doing </Col>
            </Row>
            <Row>
              <Col>
                <ul id="jobdetails6">
                  <li>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li> Nullam dictum ligula a gravida porta.</li>
                  <li>
                    {" "}
                    Nam pellentesque orci ut odio blandit, sit amet elementum
                    augue venenatis.
                  </li>
                  <li>
                    {" "}
                    Vivamus semper magna suscipit leo malesuada, eu dictum velit
                    varius.
                  </li>
                  <li> Nulla non enim eu quam rutrum dictum in non urna.</li>
                  <li>
                    {" "}
                    Integer et felis a purus convallis condimentum nec vel eros.
                  </li>
                  <li>Vestibulum porta libero nec aliquet blandit.</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col id="jobdetails5">Bonus Points for Familiarity with </Col>
            </Row>
            <Row>
              <Col>
                <ul id="jobdetails6">
                  <li>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li> Nullam dictum ligula a gravida porta.</li>
                  <li>
                    {" "}
                    Nam pellentesque orci ut odio blandit, sit amet elementum
                    augue venenatis.
                  </li>
                  <li>
                    {" "}
                    Vivamus semper magna suscipit leo malesuada, eu dictum velit
                    varius.
                  </li>
                  <li> Nulla non enim eu quam rutrum dictum in non urna.</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col id="jobdetails5">Educational Requirement </Col>
            </Row>
            <Row>
              <Col>
                <ul id="jobdetails6">
                  <li>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li> Nullam dictum ligula a gravida porta.</li>
                  <li>
                    {" "}
                    Nam pellentesque orci ut odio blandit, sit amet elementum
                    augue venenatis.
                  </li>
                  <li>
                    {" "}
                    Vivamus semper magna suscipit leo malesuada, eu dictum velit
                    varius.
                  </li>
                  <li> Nulla non enim eu quam rutrum dictum in non urna.</li>
                </ul>
              </Col>
            </Row> */}
          </Col>
          <Col id="jobdetails7">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">Name: </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter full name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="jobdetails8"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">Email: </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="jobdetails8"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">Contact number:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your contact number"
                  className="jobdetails8"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Contact Address:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  className="jobdetails8"
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Prefered location:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your prefered location"
                  className="jobdetails8"
                  value={loc}
                  onChange={(e) => setLoc(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Last education:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Last education"
                  className="jobdetails8"
                  value={edu}
                  onChange={(e) => setEdu(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Previous organization:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Previous company"
                  className="jobdetails8"
                  value={orga}
                  onChange={(e) => setOrga(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Recent project:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your recent project"
                  className="jobdetails8"
                  value={pjt1}
                  onChange={(e) => setPjt1(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Recent project:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your recent project"
                  className="jobdetails8"
                  value={pjt2}
                  onChange={(e) => setPjt2(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  No of projects you have worked on:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter no of project"
                  className="jobdetails8"
                  value={pjtNo}
                  onChange={(e) => setPjtNo(e.target.value)}
                />
              </Form.Group> */}
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">
                  Work experience:{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter total no of work experience"
                  className="jobdetails8"
                  value={we}
                  onChange={(e) => setWe(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">Notice period: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Notice period in days"
                  className="jobdetails8"
                  value={np}
                  onChange={(e) => setNp(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="jobdetails9">Expected CTC: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  className="jobdetails8"
                  value={eCtc}
                  onChange={(e) => setEctc(e.target.value)}
                />
              </Form.Group> */}
              {/* <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Attach your Adhaar card</Form.Label>
                <Form.Control
                  type="file"
                  className="jobdetails9"
                  onChange={(e) => handleAadhar(e)}
                />
              </Form.Group> */}
              {/* <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Attach your PAN card</Form.Label>
                <Form.Control
                  type="file"
                  className="jobdetails9"
                  onChange={(e) => handlePan(e)}
                />
              </Form.Group> */}
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload your CV </Form.Label>
                <Form.Control
                  type="file"
                  className="jobdetails9"
                  // value={cv}
                  onChange={(e) => handleCv(e)}
                />
              </Form.Group>
              <Button id="jobdetails10" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default JobDetails;
