import React from "react";
import "./CareerBenefit.css";
import { Col, Container, Row } from "react-bootstrap";
function CareerBenefit() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col id='cb1'>Perks & Benefits</Col>
        </Row>
        <Row>
          <Col id='cb2'>We Offer Comprehensive Benefits</Col>
        </Row>
        <Row>
          <Col id='cb3'>
            Discover our all-encompassing benefits package, ensuring complete
            coverage and support for your well-being.
          </Col>
        </Row>
        <Row>
          
        </Row>
      </Container>
    </>
  );
}

export default CareerBenefit;
