import React from "react";
import "./Home.css";
import { Container, Row, Col } from "react-bootstrap";
import HeroSection from "../../components/HeroSection/HeroSection";
import AboutComp from "../../components/AboutComp/AboutComp";
import Tile1 from "../../components/Tile1/Tile1";
import Tile2 from "../../components/Tile2/Tile2";
import Tile3 from "../../components/Tile3/Tile3";
import Tile4 from "../../components/Tile4/Tile4";
import Expertise from "../../components/Expertise/Expertise";
import TechAd from "../../components/TechAd/TechAd";
import CodekartMap from "../../components/CodekartMap/CodekartMap";
import Achievements from "../../components/Achievements/Achievements";
import Partners from "../../components/Partners/Partners";
import Testimonial from "../../components/Testimonial/Testimonial";

function Home() {
  return (
    <>
      <HeroSection />

      <Container fluid>
        <Row id="home1"></Row>
      </Container>
      <AboutComp />
      <Container fluid id="home2">
        <Row>
          <Col xs={12} md={6} lg={6} xl={6} sm={12} id="home4">
            <Tile1 />
          </Col>
          <Col xs={12} md={6} lg={6} xl={6} sm={12} id="home5">
            <Tile2 />
          </Col>
        </Row>
        <Row id="home2">
          <Col xs={12} md={6} lg={6} xl={6} sm={12} id="home4">
            {" "}
            <Tile3 />{" "}
          </Col>
          <Col id="home4">
            {" "}
            <Tile4 />
          </Col>
        </Row>
      </Container>
      <Expertise />
      <TechAd />
      {/* <Testimonial /> */}
      {/* <Achievements /> */}
      <Partners />
      <CodekartMap />
      {/*     
      

    
        
      
     
  
       
     
    
 
        <CodekartMap/> */}
    </>
  );
}

export default Home;
