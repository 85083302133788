import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function CodekartMap() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col style={{ padding: "0px", margin: "0px" }}>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3740.2852699916957!2d85.83515799999999!3d20.371124599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909ee33cbd993%3A0x7656054823a60bbf!2sCodekart%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1697190799444!5m2!1sen!2sin"
                height="400"
                style={{ border: "0", width: "100%", opacity: "97%" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CodekartMap;
