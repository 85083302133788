import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js"; // Ensure to import 'registerables' along with 'Chart'

Chart.register(...registerables);

const RevenueBarChart = () => {
  const chartContainer = useRef(null);
  let myChart = null; // Declare myChart outside useEffect to track the current chart instance

  useEffect(() => {
    const years = ["2023", "2022", "2021"];
    const revenueData = [20000000, 10000000, 8500000];

    const ctx = chartContainer.current;

    if (ctx) {
      if (myChart) {
        myChart.destroy(); // Destroy the previous chart instance
      }

      myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: years,
          datasets: [
            {
              label: "Revenue",
              data: revenueData,
              backgroundColor: [
                "rgba(255, 99, 132, 0.6)",
                "rgba(54, 162, 235, 0.6)",
                "rgba(255, 206, 86, 0.6)",
                "rgba(75, 192, 192, 0.6)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: "y",
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
            title: {
              display: true,
              text: "Company Revenue Growth Over 4 Years in INR",
              font: { size: 12 },
            },
          },
        },
      });
    }
  }, []);

  return (
    <canvas
      ref={chartContainer}
      style={{ height: "500 px", width: "800px", margin: "20px auto" }}
    />
  );
};

export default RevenueBarChart;
