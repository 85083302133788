import React from "react";
import "./HeroSection.css";
import { Container, Row, Col } from "react-bootstrap";
import Mob from "../../Assets/Mobile.png";
import { motion } from "framer-motion";

function HeroSection() {
  const buttonVariants = {
    hover: {
      scale: 1.1,
      backgroundColor: "white",
      color: "gray",
    },
    initial: {
      scale: 1,
      backgroundColor: "transparent",
      color: "white",
    },
  };

  return (
    <>
      <Container fluid id="hero1">
        <Container>
          <Row>
            <Col Id="hero3" md={6} lg={6} xl={6} xs={12} sm={12}>
              <Row>
                <Col id="hero2" className="animate__animated animate__fadeInLeft">
                  Where IT Mastery Meets Outsourcing Excellence
                </Col>
              </Row>
              <Row>
                <Col id="hero4">
                  Encapsulates our commitment to embodying simplicity in
                  technology, fostering innovation, and nurturing a community
                  where solutions to everyday challenges thrive.
                </Col>
              </Row>
              <Row>
                <Col style={{ padding: "0px" }}>
                  <motion.button
                    className="font-bold py-2 px-4 rounded-full border-gray border-2"
                    whileHover="hover"
                    initial="initial"
                    variants={buttonVariants}
                    id="hero5"
                  >
                    Explore project
                  </motion.button>
                </Col>
              </Row>
            </Col>

            <Col className="d-none d-md-block"> {/* Hide on mobile, show on medium and above */}
              <motion.img
                src={Mob}
                key={Mob}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 3 }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default HeroSection;
