import React, { useState } from "react";
import { motion } from "framer-motion";
import { MdMenu, MdClose } from "react-icons/md";
import "./Navbar.css";
import Logo from "../../Assets/Logo.png";
import GetQuoteModal from "../GetQuoteModal/GetQuoteModal";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  let Links = [
    // { name: "Home", link: "home" },
    { name: "Home", link: "/" },
    // { name: "Portfolio", link: "/portfolio" },
    { name: "Outsourcing", link: "/outsourcing" },
    { name: "Career", link: "/career" },
    { name: "Get Quote" },
  ];

  let [open, setOpen] = useState(false);

  const menuButtonVariants = {
    open: { rotate: 90 },
    closed: { rotate: 0 },
  };

  const menuVariants = {
    open: { top: "80px" },
    closed: { top: "-490px" },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // console.log("......................", isModalOpen);

  const handleGetQuoteClick = () => {
    setIsModalOpen(true);
  };

  const closeQuoteModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  return (
    <div className="w-full" style={{ backgroundColor: "#F5F5F7" }}>
      <div className="container mx-auto lg:container md-container fluid">
        <div
          className="md:flex items-center justify-between py-3"
          style={{ fontFamily: "Work Sans" }}
        >
          <motion.div
            id="nav2"
            className="font-bold text-2xl cursor-pointer flex items-center text-gray-800"
            initial={{ x: -100 }}
            animate={{ x: 0 }}
          >
            <span className="text-3xl text-indigo-600 pt-2">
              <ion-icon name="logo-ionic"></ion-icon>
            </span>
            <img
              src={Logo}
              alt="Logo"
              className="nav3"
              onClick={() => navigate("/")}
            />
          </motion.div>

          <motion.div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
            variants={menuButtonVariants}
            initial="closed"
            animate={open ? "open" : "closed"}
          >
            {open ? <MdClose /> : <MdMenu />}
          </motion.div>

          <motion.ul
            id="nav1"
            className={`md:flex md:items-center md:pb-0 pb-12 ${
              open ? "pt-12" : ""
            } absolute md:static md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in`}
            variants={menuVariants}
            initial="closed"
            animate={open ? "open" : "closed"}
          >
            {Links.map((link) => (
              <motion.li
                key={link.name}
                className="md:ml-8 text-l md:my-0 my-7"
                initial={{ x: -100 }}
                animate={{ x: 0 }}
              >
                <a
                  href={link?.link}
                  id="nav2"
                  onClick={() => {
                    link.name == "Get Quote" && setIsModalOpen(true);
                  }}
                  className={`text-gray-800 hover:text-gray-400 duration-500 ${
                    link.name === "Get Quote" ? "font-bold" : ""
                  }`}
                >
                  {link.name}
                </a>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </div>
      <GetQuoteModal
        isOpen={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        onClose={closeQuoteModal}
      />
    </div>
  );
};

export default Navbar;
