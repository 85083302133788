import React from "react";
import "./TechAd.css";
import { Col, Container, Row } from "react-bootstrap";
function TechAd() {
  return (
    <>
      <Container fluid id="techad1">
        <Row>
          <Col id="techad2">Expert in Trending Technologies</Col>
        </Row>
        <Row>
            <Col id="techad3">
            "Pioneering the way in trending technologies, we lead the path of innovation and expertise."
            </Col>
        </Row>
      </Container>
    </>
  );
}

export default TechAd;
