import React from "react";
import "./Portfolio.css";
import TopWorks from "../../components/TopWorks/TopWorks";
import { Button, Col, Container, Row } from "react-bootstrap";
import ProjectComp from "../../components/ProjectComp/ProjectComp";
import Marquee from "react-fast-marquee";
import image1 from "../../Assets/Slider/Image1.png";
import image3 from "../../Assets/Slider/Image3.png";
import image4 from "../../Assets/Slider/Image4.png";
import image5 from "../../Assets/Slider/Image5.png";
import image6 from "../../Assets/Slider/Image6.png";

function Portfolio() {
  return (
    <>
      <TopWorks />
      <Container fluid id="portfolio1">
        <Container>
          <Row>
            <Col id="expertise4">
              <div id="expertise5">
                <Row>
                  <Col>
                    <Button id="expertise6"> Services</Button>
                  </Col>
                  <Col>
                    <Button id="expertise7"> Products</Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <ProjectComp />{" "}
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <ProjectComp />{" "}
            </Col>
          </Row>

          <Row>
            <Col>
              {" "}
              <ProjectComp />{" "}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Row style={{ padding: "0px", margin: "0px" }}>
          <Marquee
            style={{ padding: "0px", margin: "0px" }}
            speed={60}
            delay={8}
          >
            <img alt="" src={image1} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image3} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image4} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image5} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image6} style={{ marginLeft: ".5rem" }} />
          </Marquee>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <Marquee
            style={{ padding: "0px", margin: "0px" }}
            speed={80}
            delay={1}
          >
            <img alt="" src={image1} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image3} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image4} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image5} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image6} style={{ marginLeft: ".5rem" }} />
          </Marquee>
        </Row>
      </Container>
      <Container fluid id="portfolio2">
        <Container>
          <Row>
            <Col md={6} lg={6} xl={6} xs={12}>
              <Row>
                <Col id="portfolio3"> Contact us for business queries </Col>
              </Row>
              <Row>
                <Col id="portfolio4">
                  {" "}
                  For all business inquiries, please don't hesitate to reach out
                  to us. Our dedicated team is here to assist you from Monday to
                  Friday during our regular business hours.
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col id="portfolio5">
                  <Button id="portfolio6"> Contact us</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Portfolio;
