import React from "react";
import "./Tile1.css";
import { Container, Row } from "react-bootstrap";
import { FiArrowUpRight } from "react-icons/fi";

function Tile1() {
  const titleStyle = {
    color: "white",
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    textAlign: "center",
    fontFamily: "Work Sans",
    fontWeight: "700",
  };

  const buttonStyle = {
    color: "white",
    position: "absolute",
    top: "34%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    textAlign: "center",
    borderRadius: "3rem",
    border: "1px solid white",
    padding: ".5rem 2rem",
    transition: "background-color 0.3s",
  };

  return (
    <>
      <Container fluid style={{ padding: "0px" }}>
        <Row id="tile1" className="zoom-on-hover">
          <div className="image-bg">
            <div style={titleStyle} id="tiletext2">
              Your Growth,
              <br />
              Our Resources, Seamless Synergy.
            </div>
            {/* <div style={buttonStyle}>
              <button
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {" "}
                Know more <FiArrowUpRight />{" "}
              </button>
            </div> */}
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Tile1;
