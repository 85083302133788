import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAdNc98JoSoTPpZeAXnI02M0jSPmC7p88I",
  authDomain: "codekartwebsite-b0c33.firebaseapp.com",
  projectId: "codekartwebsite-b0c33",
  storageBucket: "codekartwebsite-b0c33.appspot.com",
  messagingSenderId: "195043679081",
  appId: "1:195043679081:web:7dac69e88bc43952407bf8",
  measurementId: "G-FDPVWJ3EQZ",
};

initializeApp(firebaseConfig);
const db = getFirestore();
const colRef = collection(db, "job_post");
const colRefQuote = collection(db, "quote");

const fileDb = getStorage();

export { db, fileDb, colRef, colRefQuote };
