import { Toaster } from "react-hot-toast";
import "./App.css";
import "./CustomScrollbar.css";
import RootRouter from "./navigations/RootRouter";

function App() {
  return (
    <>
      <Toaster />
      {/* <Home/> */}
      {/* <Portfolio/> */}
      {/* <Career/> */}
      {/* <JobDetails/> */}
      {/* <Outsourcing/> */}
      <RootRouter />
    </>
  );
}

export default App;
