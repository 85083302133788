import React, { useState } from "react";
import "./Expertise.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import CardComp from "../CardComp/CardComp";
import Mobicon from "../../Assets/MobDev.png";
import Pcapp from "../../Assets/Pcapp.png";
import Customapp from "../../Assets/Customapp.png";
import Ux from "../../Assets/Ux.png";
import Testing from "../../Assets/Testing.png";
import Outsource from "../../Assets/Outsource.png";
import GetQuoteModal from "../GetQuoteModal/GetQuoteModal";
function Expertise() {
  const [activeSection, setActiveSection] = useState("Services"); // Default to "Services"

  const toggleSection = (section) => {
    setActiveSection(section);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // console.log("......................", isModalOpen);

  const handleGetQuoteClick = () => {
    setIsModalOpen(true);
  };

  const closeQuoteModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Container fluid id="expertise1">
        <Row>
          <Col id="expertise2">Our Expertise</Col>
        </Row>
        <Row>
          <Col id="expertise3">
            Empowering Growth through Exceptional Mobile and Web Apps.
          </Col>
        </Row>

        <Row>
          <Col id="expertise4">
            <div id="expertise5">
              <Row>
                <Col>
                  <Button
                    id={
                      activeSection === "Services" ? "expertise6" : "expertise7"
                    }
                    onClick={() => toggleSection("Services")}
                  >
                    {" "}
                    Services
                  </Button>
                </Col>
                <Col>
                  <Button
                    id={
                      activeSection === "Industry" ? "expertise6" : "expertise7"
                    }
                    onClick={() => toggleSection("Industry")}
                  >
                    {" "}
                    Industry
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Container id="expertise8">
          {activeSection === "Services" && (
            <div>
              <Row id="expertise9">
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Mobicon}
                    text="Empowering your vision with cutting-edge mobile app development, delivering innovative solutions that captivate users and drive success."
                    title="Mobile app developemt"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Pcapp}
                    text="Crafting web app solutions that redefine online experiences, combining innovation and functionality for your digital success."
                    title="Web app developemt"
                  />
                </Col>
                <Col>
                  {" "}
                  <CardComp
                    icon={Customapp}
                    text="Tailoring custom app solutions to match your unique needs, ensuring your digital goals are met with precision and efficiency."
                    title="Custom app developemt"
                  />
                </Col>
              </Row>
              <Row id="expertise9">
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Ux}
                    text="Unlock the potential of your digital products with our exceptional UX/UI design services, ensuring user satisfaction and engagement."
                    title="UI/UX Design"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Testing}
                    text="Delivering quality assurance and testing solutions that guarantee flawless performance and reliability for your digital projects."
                    title="QA Testing"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Outsource}
                    text="Efficiently scale your workforce and optimize resources through our expert outsourcing solutions, tailored to meet your specific needs and goals.."
                    title="Resource outsourcing"
                  />
                </Col>
              </Row>
            </div>
          )}
          {activeSection === "Industry" && (
            <div>
              <Row id="expertise9">
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    text="Fintech, short for financial technology, is a rapidly evolving industry that leverages technology to improve and innovate financial services. "
                    title="Finetch"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Pcapp}
                    text="Healthcare is the industry and system responsible for the prevention, diagnosis, treatment, and management of illnesses and medical conditions to maintain or improve the well-being of individuals and communities."
                    title="Healthcare"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Customapp}
                    text="E-hailing, short for electronic hailing, refers to the process of booking and securing transportation services, such as taxis or rideshares, through a digital platform or mobile app."
                    title="E-hailing"
                  />
                </Col>
              </Row>
              <Row id="expertise9">
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Ux}
                    text="E-commerce, short for electronic commerce, is the buying and selling of goods and services over the internet."
                    title="E-commerce"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Testing}
                    text="E-learning, short for electronic learning, refers to the use of electronic or digital technologies to facilitate and deliver education and training outside "
                    title="E-learning"
                  />
                </Col>
                <Col id="expertise13" md={4} lg={4} xl={4} sm={12} xs={12}>
                  {" "}
                  <CardComp
                    icon={Outsource}
                    text="Entertainment refers to activities or forms of media that provide amusement, enjoyment, and relaxation to individuals or groups."
                    title="Entertainment"
                  />
                </Col>
              </Row>
            </div>
          )}
        </Container>
        <Row>
          <Col id="expertise11">
            Need more information about our services !{" "}
            <span
              id="expertise10"
              onClick={() => setIsModalOpen(true)}
              style={{ cursor: "pointer" }}
            >
              {" "}
              Lets connect
            </span>
          </Col>
        </Row>

        <GetQuoteModal
          isOpen={isModalOpen}
          onHide={() => {
            setIsModalOpen(false);
          }}
          onClose={closeQuoteModal}
        />
      </Container>
    </>
  );
}

export default Expertise;
