import React, { useEffect, useState } from "react";
import "./CareerHero.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import careerheroimage from "../../Assets/Careerheroimage.png";
import { FiSearch } from "react-icons/fi";
function CareerHero() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Add an event listener to track window resize and update the window width state.
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    // Clean up the event listener on component unmount.
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);
  return (
    <>
      <Container fluid id="careerhero1">
        <Container id="maincontainer">
          <Row>
            <Col md={6} lg={6} xl={6} xs={12} sm={12}>
              <Row>
                <Col id="careerhero7">Get The Right Job You Deserve</Col>
              </Row>
              <Row>
                <Col id="careerhero8">
                  Each month, more than 3 million job seekers turn to website in
                  their search for work, making over 140,000 applications every
                  single day
                </Col>
              </Row>
              <Row id="careerhero4">
                <Col id="careerhero3">
                  <Row id="careerhero6">
                    <Col md={8} lg={8} xl={8} sm={10} xs={10} id="careerhero7">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Type your job"
                          id="careerhero9"
                        />
                      </Form.Group>
                    </Col>
                    <Col id="careerhero10" md={4} lg={4} xl={4} xs={2} sm={2}>
                      {" "}
                      {windowWidth > 600 ? (
                        <Button id="careerhero5">Search</Button>
                      ) : (
                        <Button id="careerhero13">
                          <FiSearch />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col id="careerhero12">
                  <span id="careerhero11"> Popular searches:</span> React js,
                  PHP, Wordpress, UI/UX
                </Col>
              </Row>
            </Col>
            <Col>
              <img src={careerheroimage} id="careerhero2" alt="" />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default CareerHero;
