import React from "react";
import "./Career.css";
import { Container, Row, Col } from "react-bootstrap";
import CareerHero from "../../components/CareerHero/CareerHero";
import CareerBenefit from "../../components/CareerBenefit/CareerBenefit";
import Careercard from "../../components/Careercard/Careercard";
import homeicon from "../../Assets/Career/homeicon.png";
import sunicon from "../../Assets/Career/sunicon.png";
import clockicon from "../../Assets/Career/clockicon.png";
import Openings from "../../components/Openings/Openings";

function Career() {
  return (
    <>
      <CareerHero />
      <CareerBenefit />

      <Container>
        <Row id="career1">
          <Col
            md={4}
            lg={4}
            xl={4}
            sm={12}
            xs={12}
            style={{ marginBottom: "1rem" }}
          >
            {" "}
            <Careercard
              icon={homeicon}
              title="Remote Work Options"
              description="After Covid 19 pandemic , working remotely has become the norm for many companies."
            />{" "}
          </Col>
          <Col
            md={4}
            lg={4}
            xl={4}
            sm={12}
            xs={12}
            style={{ marginBottom: "1rem" }}
          >
            {" "}
            <Careercard
              icon={sunicon}
              title="Unlimited PTO"
              description="Offering this perk will resonate among employees and job seekers who want to work."
            />{" "}
          </Col>
          <Col
            md={4}
            lg={4}
            xl={4}
            sm={12}
            xs={12}
            style={{ marginBottom: "1rem" }}
          >
            {" "}
            <Careercard
              icon={clockicon}
              title="Flexible Hours"
              description="Employees break down their workday into non-consecutive chunks of time — 8 a.m. to noon and 4 p.m. to 8 p.m."
            />{" "}
          </Col>
        </Row>
      </Container>
      <Openings />
    </>
  );
}

export default Career;
