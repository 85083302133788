import React from 'react'
import "./Tile3.css";
import {Container, Row, Col} from "react-bootstrap"
function Tile3() {

  const titleStyle = {
    color: "Black",
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    textAlign: "center",
    fontFamily: "Work Sans",
    fontWeight: "700",
    fontSize: "1.2rem",
  };

  
  return (
    <>
    
    <Container  fluid style={{padding:'0px'}}  >

<Row  id="tile3" className='zoom-on-hover3' >
<div className='image-bg3'>
<div style={titleStyle}>
             
Automate, Create, Innovate.
            </div>

</div>

</Row>
    </Container>  
    
    </>
  )
}

export default Tile3