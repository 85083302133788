import React from "react";
import "./Openings.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { IoMdArrowDropright } from "react-icons/io";
import { useNavigate } from "react-router-dom";
// firebase1
import { getDocs } from "@firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { colRef } from "../../firebase";

function Openings() {
  const [fireData, setFireData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDocs(colRef)
      .then((res) => {
        // console.log("===================>", res?.docs);
        let postedJobs = [];
        res?.docs?.forEach((doc) => {
          postedJobs.push({ ...doc?.data(), id: doc?.id });
        });
        // console.log("================================>", postedJobs);
        setFireData(postedJobs);
      })
      .catch((err) => console.log(err));
  }, []);

  function convertTimestampToDateString(timestamp) {
    if (
      timestamp &&
      timestamp.seconds !== undefined &&
      timestamp.nanoseconds !== undefined
    ) {
      const milliseconds =
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const date = new Date(milliseconds);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so we add 1
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    } else {
      return "Invalid Timestamp";
    }
  }

  return (
    <>
      <Container fluid id="openings1">
        <Container>
          <Row>
            <Col id="openings2">Career Openings</Col>
          </Row>
          <Row>
            <Col id="openings3">
              We’re always looking for creative, talented self-starters to join
              the Codekart family. Check out our open roles below and fill out
              an application.
            </Col>
          </Row>

          <Row id="openings4">
            <Col>
              <Row>
                <Col id="openings6" md={4} lg={4} xl={4}>
                  Vacancy
                </Col>
                <Col md={3} lg={3} xl={3}>
                  <Col id="openings6">Experience(years)</Col>
                </Col>
                <Col md={3} lg={3} xl={3}>
                  <Row>
                    <Col id="openings6">Last date</Col>
                  </Row>
                </Col>
                <Col md={2} lg={2} xl={2}></Col>
              </Row>

              {fireData?.map((item, i) => (
                <Row key={i} className="openings10">
                  <Col id="openings5" md={4} lg={4} xl={4}>
                    {item?.job_name}
                  </Col>
                  <Col md={3} lg={3} xl={3}>
                    <Row>
                      <Col id="openings7">{item?.experience}</Col>
                    </Row>
                  </Col>
                  <Col md={3} lg={3} xl={3}>
                    <Row>
                      <Col id="openings7">
                        {convertTimestampToDateString(item?.last_date)}
                      </Col>
                    </Row>
                  </Col>
                  <Col><Button  id="openings8"     
                      onClick={() => {
                        navigate("/jobdetails", { state: { id: item } });
                      }}> 
                 
                    Apply job
                  </Button>
                 
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Openings;
