import React from "react";
import "./ProjectComp.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Container, Row, Col } from "react-bootstrap";
import Dp from "../../Assets/ProfilePic.png";
import AppPresent from "../../Assets/AppPresent.png";
function ProjectComp() {
  return (
    <>
      <Container fluid id="projectcomp1">
        <Row>
          {" "}
          <Col>
            <Tabs
              defaultActiveKey="Overview"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Overview" title="Overview">
                <Row>
                  <Col md={8} lg={8}>
                    <Row>
                      <Col id="projectcomp3"> EDRYV CAB SERVICES</Col>
                    </Row>
                    <Row>
                      <Col id="projectcomp4">
                        With unwavering dedication and relentless hard work,
                        we've achieved remarkable success in developing and
                        launching these apps. Our journey has been marked by
                        countless hours of effort and a commitment to
                        excellence. Today, as our apps thrive in the market, we
                        are proud of the impact they've made and the trust
                        they've garnered from users. We look forward to
                        continuing this journey, pushing the boundaries of
                        innovation, and delivering even greater value to our
                        clients and the market at large.
                      </Col>
                    </Row>
                    <Row>
                      <Col id="projectcomp2">
                        <div>
                          {" "}
                          <img src={Dp} style={{ width: "70%" }} alt="" />
                        </div>
                        <div>
                          <Row>
                            {" "}
                            <Col id="projectcomp5"> Jhon</Col>{" "}
                          </Row>
                          <Row>
                            {" "}
                            <Col id="projectcomp6">
                              {" "}
                              Manager of a company{" "}
                            </Col>{" "}
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={{ order: "first" }} md={{ order: "last" }}>
                    <img src={AppPresent} alt="" />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="Features" title="Features">
                Tab content for Features
              </Tab>
              <Tab eventKey="Techstack used" title="Techstack used">
                Tab content for Techstack used
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProjectComp;
