import React from "react";
import "./CardComp.css";
import { Col, Container, Row } from "react-bootstrap";

function CardComp(props) {
  return (
    <>
      <Container fluid>
        <Row id="card1">
          <Col>
            <Row>
              <Col id="card2">
                {" "}
                <img src={props.icon} alt="" /> {props.title}
              </Col>
            </Row>
            <Row>
              <Col id="card3">{props.text}</Col>
            </Row>
            {/* <Row>
              <Col id="card4">Know more</Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CardComp;
