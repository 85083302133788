import React from "react";
import "./Partners.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import Partner1 from "../../Assets/Partners/Company1.png";
import Partner2 from "../../Assets/Partners/Company2.png";
import Partner3 from "../../Assets/Partners/Company3.png";
import Partner4 from "../../Assets/Partners/Company4.png";
import Partner5 from "../../Assets/Partners/Company5.png";
import Partner6 from "../../Assets/Partners/Company6.png";

function Partners() {
  var settings = {
    dots: false,
    // infinite: false,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <Container
        style={{ marginTop: "3rem", marginBottom: "3rem", width: "96%" }}
      >
        <Slider {...settings}>
          <div id="partner1">
            <img alt="" src={Partner1} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner2} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner3} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner4} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner5} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner6} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner1} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner2} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner3} />
          </div>
          <div id="partner1">
            <img alt="" src={Partner6} />
          </div>
        </Slider>
      </Container>
    </>
  );
}

export default Partners;
