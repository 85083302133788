import React from "react";
import "./Outsourcing.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import image1 from "../../Assets/Outsource/1out.png";
import image2 from "../../Assets/Outsource/2out.png";
import image3 from "../../Assets/Outsource/3out.png";
import image4 from "../../Assets/Outsource/4out.png";
import image5 from "../../Assets/Outsource/5out.png";
import image6 from "../../Assets/Outsource/6out.png";
import image7 from "../../Assets/Outsource/7out.png";
import image8 from "../../Assets/Outsource/8out.png";
import image9 from "../../Assets/Outsource/9out.png";
import image10 from "../../Assets/Outsource/10out.png";
import video1 from "../../Assets/Videos/video1.png";
import video2 from "../../Assets/Videos/video2.png";
import video3 from "../../Assets/Videos/video3.png";
import Toprecruiters from "../../Assets/Toprecruiters.png";
import RevenueBarChart from "../../components/RevenueBarChart/RevenueBarChart";
import { useNavigate } from "react-router-dom";
function Outsourcing() {
  const navigate = useNavigate();
  const ViewOpenings = () => {
    navigate("/career");
  };
  return (
    <>
      <Container fluid id="outsourcing1">
        <Row id="outsourcing2">
          <Marquee
            style={{ padding: "0px", margin: "0px", zIndex: "0" }}
            speed={60}
            delay={8}
          >
            <img alt="" src={image1} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image2} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image3} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image4} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image5} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image6} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image7} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image8} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image9} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image10} style={{ marginLeft: ".5rem" }} />
          </Marquee>
        </Row>
        <Row>
          <Marquee
            style={{ padding: "0px", margin: "0px", zIndex: "0" }}
            speed={100}
            delay={15}
          >
            <img alt="" src={image1} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image2} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image3} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image4} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image5} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image6} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image7} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image8} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image9} style={{ marginLeft: ".5rem" }} />
            <img alt="" src={image10} style={{ marginLeft: ".5rem" }} />
          </Marquee>
        </Row>

        <Row>
          <Col id="outsourcing3">
            Navigating HR Challenges, Maximizing Potential.
          </Col>
        </Row>
        <Row>
          <Col id="outsourcing4">
            We extend our heartfelt gratitude to our exceptional team and
            dedicated members whose relentless efforts have been the <br />{" "}
            driving force behind our success.Together, we look forward to
            achieving even greater milestones in the future.
          </Col>
        </Row>
      </Container>
      <Container fluid id="outsourcing5">
        <Container>
          <Row>
            <Col id="outsourcing6">
              Empowering the Success of 24+
              <br />
              Companies & 100+ Enterprises.
            </Col>
          </Row>
          <Row>
            <Col id="outsourcing7">
              <img src={Toprecruiters} alt="" />
            </Col>
          </Row>
          <Row>
            <Col id="outsourcing9">
              <Button id="outsourcing8" onClick={ViewOpenings}>
                {" "}
                View openings{" "}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <Container fluid>
        <Row>
          <Col>
            <RevenueBarChart style={{ width: "50%" }} />
          </Col>
          <Col id="outsourcing15">
            <Row>
              <Col id="outsourcing14">
                The Company's Unwavering <br /> Revenue growth
              </Col>
            </Row>
            <Row>
              <Col id="outsourcing16">
                Over the course of four years, our company's revenue hasshown a
                remarkable ascent, <br /> reflecting our unwavering dedication
                and strategic efforts. This persistent growth propels us <br />{" "}
                towards future achievements and solidifies our commitment to
                excellence.
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> */}

      <Container fluid id="outsourcing10">
        <Container>
          <Row>
            <Col id="outsourcing12">
              <Row>
                <Col id="outsourcing17">Hiring Excellence</Col>
              </Row>
              <Row>
                <Col id="outsourcing18">
                  where the pursuit of excellence meets unparalleled
                  opportunity. We're not just hiring, we're seeking the best.
                  Embrace thejourney with us and unlock a worldof extraordinary
                  offers tailoredjust for you.
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Outsourcing;
