import React from "react";
import "./AboutComp.css";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
function AboutComp() {
  const buttonVariants = {
    hover: {
      scale: 1.02,
      backgroundColor: "gray-200",
      color: "white",
    },
    initial: {
      scale: 1,
      backgroundColor: "black",
      color: "white",
    },
  };

  return (
    <>
      <Container fluid id="about1">
        <Container>
          <Row>
            <Col md={4} lg={4} xl={4} sm={12} xs={12}>
              <Row>
                <Col id="about2">About us</Col>
              </Row>
              <Row>
                <Col id="about3">
                  Innovating Excellence, Every Step of the Way.
                </Col>
              </Row>
              <Row>
                <Col id="about4">
                  At Codekart, we are more than just a tech software company –
                  we are your trusted partner in navigating the dynamic world of
                  technology. Established with a passion for innovation and a
                  commitment to excellence, we've been crafting cutting-edge
                  software solutions since our inception.
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link to={"/portfolio"}>
                    <motion.button
                      className="font-bold py-2 px-4 rounded-full border-gray border-2"
                      whileHover="hover"
                      initial="initial"
                      variants={buttonVariants}
                      id="hero5"
                    >
                      Our portfolio
                    </motion.button>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col md={2} lg={2} xl={2}>
              {" "}
            </Col>
            <Col md={1} lg={1} xl={1} className="d-none d-md-block">
              {" "}
              <hr />{" "}
            </Col>
            <Col md={1} lg={1} xl={1}>
              {" "}
            </Col>
            <Col>
              <Row id="about5">
                <Col>
                  <div id="about6">20+ </div>

                  <div id="about7"> Project completed</div>
                </Col>
              </Row>
              <Row id="about5">
                <Col>
                  <div id="about6">10+ </div>

                  <div id="about7"> Happy client</div>
                </Col>
              </Row>
              <Row id="about5">
                <Col>
                  <div id="about6">500+ </div>

                  <div id="about7"> People outsourced</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default AboutComp;
