import React, { Component } from "react";
import "./Loader.css";
class Loader extends Component {
  render() {
    return (
      <div>
        {/* Your SVG code goes here */}
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 193 100"
          xmlSpace="preserve"
          width="193"
          height="100"
        >
          <style type="text/css">
            {`.st0{fill:none;stroke:#020202;stroke-miterlimit:10;}`}
          </style>
          <path
            class="st0 svg-elem-1"
            d="M7.4,58.7c-0.4-0.2-0.7-0.4-0.9-0.7c-0.3-0.4-0.5-0.8-0.6-1.2c-0.2-0.4-0.3-0.9-0.3-1.3
          c-0.1-0.5-0.1-0.9-0.1-1.3c0-0.7,0.1-1.2,0.2-1.7C5.8,52,6,51.6,6.3,51.3c0.3-0.3,0.6-0.6,1.1-0.8c0.4-0.2,0.9-0.4,1.5-0.5
          c3.2-1.3,6.4-2.8,9.7-4.3c3.2-1.6,6.6-3.1,10.2-4.7c0.2-0.2,0.4-0.3,0.7-0.5c0.3-0.2,0.6-0.3,1-0.5c0.3-0.2,0.7-0.3,1-0.4
          c0.4-0.1,0.7-0.2,1-0.2c0.5,0,1,0.2,1.4,0.5c0.5,0.3,0.9,0.7,1.2,1.1c0.4,0.5,0.7,1,0.9,1.6c0.2,0.6,0.3,1.1,0.3,1.7
          c0,0.7-0.1,1.2-0.3,1.6c-0.2,0.4-0.4,0.8-0.7,1c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.2,0.5v0l-14.9,7l14.2,6.8
          c0.3,0.1,0.6,0.2,1.1,0.3c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.5,1.1,0.5,1.8c0,0.5-0.1,1.1-0.3,1.6
          c-0.2,0.5-0.4,1-0.7,1.5c-0.3,0.4-0.7,0.8-1.2,1.1c-0.5,0.3-1.1,0.4-1.7,0.4c-0.7,0-1.4-0.1-2-0.4c-0.6-0.3-1.1-0.5-1.5-0.8
          c-0.6-0.3-1.4-0.7-2.5-1.3c-1.1-0.6-2.3-1.2-3.7-1.9c-1.3-0.7-2.8-1.4-4.3-2.2c-1.5-0.7-2.9-1.5-4.3-2.2c-1.4-0.7-2.7-1.3-3.9-1.9
          C9.1,59.4,8.1,59,7.4,58.7z"
          ></path>
          <path
            class="st0 svg-elem-2"
            d="M94.7,45.8c-0.5-1.6-1.3-3.1-2.4-4.5c-1.1-1.4-2.5-2.6-4.3-3.5c-1.8-0.9-4-1.4-6.5-1.4h-18
          c-1.4,0-2.9,0.2-4.5,0.7c-1.6,0.5-3.2,1.3-4.6,2.4c-1.4,1.1-2.5,2.5-3.5,4.3c-0.9,1.8-1.4,3.9-1.4,6.5V64c0,2.6,0.5,4.8,1.4,6.6
          c1,1.8,2.1,3.2,3.5,4.3c1.4,1.1,2.9,1.9,4.6,2.4c1.6,0.5,3.1,0.7,4.5,0.7h18c0.9,0,1.9-0.1,3-0.3c1.1-0.2,2.1-0.6,3.2-1
          c1-0.5,2-1.1,3-1.8c1-0.7,1.8-1.6,2.5-2.7c0.7-1,1.3-2.2,1.7-3.6c0.4-1.4,0.7-2.9,0.7-4.7V50.4C95.5,49,95.2,47.5,94.7,45.8z
           M85.5,64c0,1.3-0.3,2.3-1,3c-0.7,0.7-1.7,1-3,1h-18c-1.3,0-2.4-0.3-3-1s-1-1.7-1-3V50.4c0-1.3,0.3-2.3,1-3c0.7-0.7,1.7-1,3-1h18
          c1.3,0,2.3,0.3,3,1c0.7,0.7,1,1.7,1,3.1V64z"
          ></path>
          <path
            class="st0 svg-elem-3"
            d="M149.6,64c0,1.7-0.2,3.3-0.7,4.7c-0.4,1.4-1,2.6-1.7,3.6c-0.7,1-1.6,1.9-2.5,2.7c-0.9,0.7-1.9,1.3-3,1.8
          c-1,0.5-2.1,0.8-3.2,1c-1.1,0.2-2.1,0.3-3,0.3h-18c-1.7,0-3.3-0.2-4.6-0.7c-1.4-0.4-2.6-1-3.6-1.7c-1-0.7-1.9-1.6-2.7-2.5
          c-0.7-0.9-1.3-1.9-1.8-3c-0.5-1.1-0.8-2.1-1-3.2c-0.2-1.1-0.3-2.1-0.3-3V50.4c0-2.6,0.5-4.7,1.4-6.5c0.9-1.8,2.1-3.2,3.5-4.3
          c1.4-1.1,2.9-1.9,4.6-2.4s3.2-0.7,4.5-0.7h18v10h-18c-1.3,0-2.3,0.3-3,1c-0.7,0.7-1,1.7-1,3v13.5c0-1.4,0.3-2.4,1-3.1
          c0.7-0.7,1.7-1,3-1h18c1.4,0,2.4-0.3,3-1c0.7-0.7,1-1.7,1-3V18.9h10V64z"
          ></path>
          <circle class="st0 svg-elem-4" cx="55.1" cy="86.6" r="5.6"></circle>
          <circle class="st0 svg-elem-5" cx="88.8" cy="86.6" r="5.6"></circle>
          <path
            class="st0 svg-elem-6"
            d="M187.5,31.8l-26.1,0c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4h26.1c3,0,5.4-2.4,5.4-5.4
          C192.8,34.2,190.4,31.8,187.5,31.8z"
          ></path>
          <path
            class="st0 svg-elem-7"
            d="M187.5,67.1l-26.1,0c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4h26.1c3,0,5.4-2.4,5.4-5.4
          C192.8,69.5,190.4,67.1,187.5,67.1z"
          ></path>
          <path
            class="st0 svg-elem-8"
            d="M172.5,50l-11,0c-3,0-5.5,2.5-5.5,5.5c0,3,2.5,5.5,5.5,5.5h11c3,0,5.5-2.5,5.5-5.5
          C178,52.5,175.6,50.1,172.5,50z"
          ></path>
        </svg>
      </div>
    );
  }
}

export default Loader;
