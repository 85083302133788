import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavConstants } from "./NavConstants";
import Loader from "../components/Loader/Loader";
import Career from "../pages/Career/Career";
import Outsourcing from "../pages/Outsourcing/Outsourcing";
import Portfolio from "../pages/Portfolio/Portfolio";
import Footer from "../components/Footer/Footer";
import JobDetails from "../pages/JobDetails/JobDetails";
import Home from "../pages/Home/Home";
import Navbar from "../components/Navbar/Navbar";

export default function RootRouter() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
      {isLoading ? "" : <Navbar />}
      {isLoading ? (
        <div
          id={isLoading ? "initial" : "slideup"}
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Routes>
          <Route path={NavConstants.home} element={<Home />} />
          <Route path={NavConstants.career} element={<Career />} />
          <Route path={NavConstants.outsourcing} element={<Outsourcing />} />
          <Route path={NavConstants.portfolio} element={<Portfolio />} />
          <Route path={NavConstants.jobdetails} element={<JobDetails />} />
        </Routes>
      )}
      {isLoading ? "" : <Footer />}
    </Router>
  );
}
