import React from "react";
import "./TopWorks.css";
import { Container, Row, Col } from "react-bootstrap";
import Logo1 from "../../Assets/Logo1.png";
import Logo2 from "../../Assets/Logo2.png";
function TopWorks() {
  return (
    <>
      <Container fluid id="topwork1">
        <Container>
          <Row>
            <Col id="topwork2"> Top Works</Col>
          </Row>
          <Row>
            <Col id="topwork3">
              <div>
                {" "}
                <img alt="" src={Logo1} />{" "}
              </div>
              <div>
                {" "}
                <img alt="" src={Logo2} />{" "}
              </div>
              <div>
                {" "}
                <img alt="" src={Logo1} />{" "}
              </div>
              <div>
                {" "}
                <img alt="" src={Logo2} />{" "}
              </div>
              <div>
                {" "}
                <img alt="" src={Logo1} />{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col id="topwork4">
              With unwavering dedication and relentless hard work, we've
              achieved remarkable success in developing and launching these
              apps. Our journey has been marked by countless hours of effort and
              a commitment to excellence. Today, as our apps thrive in the
              market, we are proud of the impact they've made and the trust
              they've garnered from users. We look forward to continuing this
              journey, pushing the boundaries of innovation, and delivering even
              greater value to our clients and the market at large.
            </Col>
          </Row>
          <Row>
            <Col id="topwork5">
              We extend our heartfelt gratitude to our exceptional team and
              dedicated members whose relentless efforts have been the driving
              force behind our success. Together, we look forward to achieving
              even greater milestones in the future.
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default TopWorks;
