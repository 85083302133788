import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import logowhite from "../../Assets/logowhite.png";
import { AiOutlineGoogle } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { FaSquareFacebook } from "react-icons/fa6";
function Footer() {
  const fb = () => {
    window.open("https://www.facebook.com/TheCodeKart/", "_blank");
  };
  const tw = () => {
    window.open("https://twitter.com/codekart?lang=en", "_blank");
  };
  const ins = () => {
    window.open("https://www.instagram.com/thecodekart/", "_blank");
  };
  const twi = () => {
    window.open("https://in.linkedin.com/company/codekart", "_blank");
  };
  const lin = () => {
    window.open("", "_blank");
  };
  return (
    <>
      <Container fluid id="footer1">
        <Container>
          <Row>
            <Col md={4} xl={4} lg={4} xs={12}>
              {" "}
              <div id="footer2">
                {" "}
                <img src={logowhite} alt="" />
              </div>{" "}
              <div id="footer2">
                {" "}
                © 2024 All Copyrights Reserved CodeKart solution Pvt. Ltd
              </div>
            </Col>
            <Col id="footer3" md={4} xl={4} lg={4} xs={12}>
              <Row>
                <Col>
                  {" "}
                  Duplex No. 2, back side Mani Tribhuvan,Raghunathpur,
                  Bhubaneswar, Odisha751024
                </Col>
              </Row>
              <Row>
                <Col id="footer4">
                  <a href="tel:+919178518343">+91 9178518343</a>,{" "}
                  {/* <a href="tel:+917978924665">+91 7978924665</a> */}
                </Col>
              </Row>
              <Row>
                <Col id="footer4">
                  <a href="mailto:info@thecodekart.com">info@thecodekart.com</a>
                </Col>
              </Row>
            </Col>
            <Col id="footer3" md={4} xl={4} lg={4} xs={12}>
              {/* <Row>
                <Col>Privacy policy</Col>
              </Row>
              <Row>
                <Col id="footer4">Terms & condition</Col>
              </Row>
              <Row>
                <Col id="footer4">Career</Col>
              </Row> */}
              <Row>
                <Col id="footer5">
                  {/* <AiOutlineGoogle className="footer6" /> */}
                  <RiTwitterXFill className="footer6" onClick={() => tw()} />
                  <FaSquareFacebook className="footer6" onClick={() => fb()} />
                  <AiFillInstagram className="footer6" onClick={() => ins()} />
                  <AiFillLinkedin className="footer6" onClick={() => twi()} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Footer;
