import React, { useState } from "react";
import "./Tile4.css";
import { Container, Row, Col } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
function Tile4() {
  const [activeTab, setActiveTab] = useState("Mission");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const textOne =
    "CodeKart aims to democratize tech's simplicity, alleviating daily issues. Through job creation, we ensure economic stability, guiding all toward a future of tech-infused happiness.";
  const textTwo =
    "CodeKart envisions seamless tech integration for daily simplicity. Committed to accessibility, we foster joy, economic stability, and growth for all.";
  const textThree =
    "At CodeKart, we value diversity, innovation, and ethical conduct. We prioritize customer-centricity, collaboration, and continuous learning. Employee empowerment is central, contributing positively to communities and delivering tech-driven joy.";

  const renderColoredText = (text) => {
    // Define the color ranges and their corresponding character limits
    const colorRanges = [
      { color: "#00ADE7", limit: 14 },
      { color: "#80AFFA", limit: 34 },
      { color: "#44D5E", limit: 50 },
      { color: "#F4E9AC", limit: 55 },
      { color: "#FFF", limit: 70 },
    ];

    let currentIndex = 0;
    let currentColor = "#fff";

    const coloredText = colorRanges.map((range, index) => {
      const characters = text.slice(currentIndex, currentIndex + range.limit);
      currentIndex += range.limit;
      currentColor = range.color;
      return (
        <span key={index} style={{ color: currentColor }}>
          {characters}
        </span>
      );
    });
    coloredText.push(
      <span key={coloredText.length} style={{ color: currentColor }}>
        {text.slice(currentIndex)}
      </span>
    );

    return coloredText;
  };

  return (
    <>
      <Container fluid id="tile4">
        <Row>
          <Col>
            <Row>
              <Col id="tr1">Our Success Mantras</Col>
            </Row>
            <Row>
              <Col id="tr2">
                <button
                  id={activeTab === "Mission" ? "btnactive" : "btnNotactive"}
                  onClick={() => handleTabClick("Mission")}
                >
                  Mission
                </button>
                <button
                  id={activeTab === "Vision" ? "btnactive" : "btnNotactive"}
                  onClick={() => handleTabClick("Vision")}
                >
                  Vision
                </button>
                <button
                  id={activeTab === "Values" ? "btnactive" : "btnNotactive"}
                  onClick={() => handleTabClick("Values")}
                >
                  Values
                </button>
              </Col>
            </Row>
            <Row>
              <Col
                id="Mission"
                style={{
                  display: activeTab === "Mission" ? "block" : "none",
                }}
              >
                {renderColoredText(textOne)}
              </Col>
              <Col
                id="Vision"
                style={{
                  display: activeTab === "Vision" ? "block" : "none",
                }}
              >
                {renderColoredText(textTwo)}
              </Col>
              <Col
                id="Values"
                style={{
                  display: activeTab === "Values" ? "block" : "none",
                }}
              >
                {renderColoredText(textThree)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tile4;
