import React, { useState } from "react";
import "./Tile2.css";
import { Container, Row, Col } from "react-bootstrap";
import Mobile from "../../Assets/Mob.png";
import Pc from "../../Assets/Pc.png";
import { FiArrowUpRight } from "react-icons/fi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { motion } from "framer-motion";

function Tile2() {
  const [activeRow, setActiveRow] = useState("row1");

  const toggleRow = () => {
    setActiveRow(activeRow === "row1" ? "row2" : "row1");
  };

  return (
    <>
      <Container fluid id="tile2">
        <motion.div
          id="row1"
          initial={{ opacity: 1 }}
          animate={activeRow === "row1" ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          {activeRow === "row1" && (
            <Row id="row1">
              <Col>
                <Row>
                  <Col id="tt1">
                    <img src={Mobile} alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col id="tt2">Your Ideas, Our Expertise.</Col>
                </Row>
                <Row>
                  <Col id="tt3">
                    Where Innovation Meets Perfection Crafting Mobile Apps
                    Beyond Excellence
                  </Col>
                </Row>
                <Row>
                  <Col id="tt4">
                    We are the premier mobile app development company, setting
                    industry standards with our commitment to delivering
                    top-quality, cutting-edge mobile applications.
                  </Col>
                </Row>
                <Row>
                  {/* <Col id="tt5">
                    <button
                      id="tt6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      Know more <FiArrowUpRight />{" "}
                    </button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          )}
        </motion.div>
        <motion.div
          id="row2"
          initial={{ opacity: 0 }}
          animate={activeRow === "row2" ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          {activeRow === "row2" && (
            <Row id="row2">
              <Col>
                <Row>
                  <Col id="tt1">
                    <img src={Pc} alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col id="tt2">Your Apps, Our Expertise.</Col>
                </Row>
                <Row>
                  <Col id="tt3">
                    Where Innovation Meets Perfection Crafting Web Apps Beyond
                    Excellence
                  </Col>
                </Row>
                <Row>
                  <Col id="tt4">
                    We are the premier Web app development company, setting
                    industry standards with our commitment to delivering
                    top-quality, cutting-edge mobile applications.
                  </Col>
                </Row>
                <Row>
                  {/* <Col id="tt5">
                    <button
                      id="tt6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      Know more <FiArrowUpRight />{" "}
                    </button>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          )}
        </motion.div>
        <Row style={{ marginBottom: "1rem", paddingBottom: "1rem" }}>
          <Col id="tt7">
            <motion.div id="tt8" onClick={toggleRow}>
              {" "}
              <MdKeyboardArrowLeft />{" "}
            </motion.div>
            <motion.div id="tt8" onClick={toggleRow}>
              {" "}
              <MdKeyboardArrowRight />{" "}
            </motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tile2;
